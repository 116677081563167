import {createContext, ReactElement, Dispatch, FC, useState} from "react";

const initialState: [PageBaseContextState, Dispatch<React.SetStateAction<PageBaseContextState>>] = [
	{sidebarEnabled: true, sidebarOpen: true},
	() => {}
];
const PageBaseContext = createContext(initialState);

type PageBaseContextState = {
	sidebarEnabled: boolean,
	sidebarOpen: boolean
}

interface IProps {
	children: ReactElement
}

const PageBaseProvider: FC<IProps> = ({children}) => {
	const state = useState<PageBaseContextState>(initialState[0]);

	return (
		<PageBaseContext.Provider value={state}>
			{children}
		</PageBaseContext.Provider>
	);
};

export {
	PageBaseContext,
	PageBaseProvider
};