import {Box, BoxProps} from "@mui/material";
import React, {FC, useEffect, useState, isValidElement, cloneElement, ReactNode} from "react";
import {MobileHeader, NavBar, SideMenu} from "@plumeuk/shapeshift-common/pageBase";
import {makeStyles} from "tss-react/mui";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {useLocation, useMatch, useNavigate} from "react-router-dom";
import {sideMenuWidth, toolbarHeight} from "../../constants";
import {ModuleNavBar} from "../moduleNavBar";
import EditUserDetailsForm from "../editUserDetailsForm/editUserDetailsForm";
const logo = "/PlumeLogo.svg"

interface ICustomProps {
	children: React.ReactNode,
	contentWidth?: string,
	sideBarContent?: ReactNode,
	disableSideBar?: boolean
}

interface IStyleProps {
	sideMenuOpen: boolean,
	hasNavBar: boolean,
	contentWidth?: string
}

const useStyles = makeStyles<IStyleProps>()((theme, {sideMenuOpen, hasNavBar, contentWidth}) => ({
	pageBase: {
		height: "100%"
	},
	background: {
		backgroundColor: theme.palette.background.default,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: "-1",
		position: "fixed"
	},
	navBarSpacer: {
		width: "100%",
		height: toolbarHeight
	},
	contentContainer: {
		width: sideMenuOpen ? `calc(100% - ${sideMenuWidth})` : "100%",
		position: "absolute",
		overflowX: "hidden",
		transition: "all .3s ease-in-out",
		left: sideMenuOpen ? sideMenuWidth : "0px",
		boxSizing: "border-box",
		overflowY: "auto",
		padding: "25px",
		top: hasNavBar ? toolbarHeight : 0,
		height: hasNavBar ? `calc(100% - ${toolbarHeight})` : "100%",
		[theme.breakpoints.down("md")]: {
			left: 0,
			width: "100%"
		}
	},
	sideMenu: {
		"& [class*='moduleTitle']": {
			color: "white"
		}
	},
	content: {
		[theme.breakpoints.up("sm")]:{
			height: "100%"
		},
		minHeight: "100%",
		boxSizing: "border-box",
		width: "100%",
		maxWidth: contentWidth ?? "1000px",
		margin: "auto"

	}
}));

type IProps = ICustomProps & Omit<BoxProps, "children">;
type pages = "My Learning" | "Library";

type routeCallback = {
	[key in pages]: string;
};

const routesMap: routeCallback = {
	"My Learning": "/my-learning",
	"Library": "/library"
}

export const PageBase: FC<IProps> = ({sideBarContent, disableSideBar, contentWidth, children, className, ...props}) => {
	const mq = useMq();
	const pageIsMyLearning = useMatch("/my-learning");
	const location = useLocation();
	const [sideMenuOpen, setSideMenuOpen] = useState(!disableSideBar && mq.desktop);
	const {classes, cx} = useStyles({sideMenuOpen, hasNavBar: !mq.mobile || !pageIsMyLearning, contentWidth});
	const navigate = useNavigate();

	useEffect(() => setSideMenuOpen(!disableSideBar && mq.desktop), [disableSideBar, mq.desktop]);

	let pageSelected: pages | undefined = undefined;
	Object.keys(routesMap).forEach(e => {
		const page = e as pages;
		if(location.pathname.startsWith(routesMap[page]))
			pageSelected = page;
	})

	const handlePageSelect = (page: pages): void => {
		navigate(routesMap[page]);
	}

	const handleContentClick = (): void => {
		if(!mq.desktop && sideMenuOpen) setSideMenuOpen(false);
	}

	return (
		<Box className={cx(classes.pageBase, className)} {...props}>
			{!mq.mobile &&
				<NavBar<pages>
					tabs={["My Learning", "Library"]}
					onBurgerAction={() => setSideMenuOpen(prev => !prev)}
					onPageSelect={handlePageSelect}
					pageSelected={pageSelected}
					logoPath={logo}
					disableSideBar={disableSideBar}
					editUserDetailsForm={EditUserDetailsForm}
				/>}
			{mq.mobile && !pageIsMyLearning &&
				<ModuleNavBar
					onBurgerAction={() => setSideMenuOpen(prev => !prev)}
				/>}
			<SideMenu
				className={classes.sideMenu}
				open={sideMenuOpen}
				width={sideMenuWidth}
			>
				{isValidElement(sideBarContent) ? cloneElement(sideBarContent, {...sideBarContent?.props ?? {}, setSideMenuOpen}) : undefined}
			</SideMenu>
			<Box className={classes.contentContainer} onClick={handleContentClick}>
				{mq.mobile && pageIsMyLearning && <MobileHeader editUserDetailsForm={EditUserDetailsForm}/>}
				<Box className={classes.content}>
					{children}
				</Box>
			</Box>
			<Box className={classes.background} />
		</Box>
	);
};
