import {CourseSideMenuContent as CommonCourseSideMenuContent} from "@plumeuk/shapeshift-common/course";
import {moduleSlim, moduleTypeSlim} from "@plumeuk/shapeshift-types/ICourse";
import {FC, useCallback, useContext, useEffect, useState} from "react";
import {useMatch, useNavigate, useParams} from "react-router-dom";
import {CurriculumContext} from "../../contexts/curriculumContext";
import {INotificationSeverity, NotificationContext} from "@plumeuk/shapeshift-identity";
import {ICourseCurriculumModule} from "@plumeuk/shapeshift-types";
import {makeStyles} from "tss-react/mui";
import {useMq} from "@plumeuk/shapeshift-common/hooks";

const useStyles = makeStyles()((_theme) => ({
	sideMenu: {
		"[class*='moduleTitle']": {
			color: "white"
		}
	}
}));

interface ICourseSideMenuContentProps {
	setSideMenuOpen?: (open: boolean) => void;
}

export const CourseSideMenuContent: FC<ICourseSideMenuContentProps> = ({setSideMenuOpen}) => {
	const mq = useMq();
	const {classes} = useStyles();
	const pageIsModule = useMatch("/course/:courseSlug/:moduleType/:moduleSlug");
	const moduleSlug = pageIsModule?.params.moduleSlug;
	const moduleType = pageIsModule?.params.moduleType;
	const {notify} = useContext(NotificationContext);
	const {courseSlug} = useParams();
	const navigate = useNavigate();
	const {curriculumState, curriculumDispatch} = useContext(CurriculumContext);
	const [activeModule, setActiveModule] = useState<moduleSlim>();

	useEffect(() => {
		if(moduleSlug && moduleType)
			setActiveModule({slug: moduleSlug, type: moduleType as moduleTypeSlim});
	}, [moduleSlug, moduleType]);

	const handleActiveModuleCallback = useCallback((module: ICourseCurriculumModule): void => {
		navigate(`/course/${courseSlug}/${module.type}/${module.slug}`);//, {replace: !pageIsModule}

		if (mq.mobile) {
			setSideMenuOpen?.(false);
		}
	}, [navigate, setSideMenuOpen]);

	const handleSetModuleComplete = useCallback((module: moduleSlim, status: boolean): void => {
		curriculumDispatch({type: "setModuleComplete", module, status})
	}, [curriculumDispatch]);

	const handleModuleForbidden = useCallback((moduleRequest: ICourseCurriculumModule, nextModule?: ICourseCurriculumModule): void => {
		notify(`Modules must be completed in sequence. Next up is ${nextModule?.title}`, "Hey now, slow down!",INotificationSeverity.warning, 5000);
	}, [curriculumDispatch]);

	return (
		<CommonCourseSideMenuContent
			className={classes.sideMenu}
			data={curriculumState}
			courseSlug={courseSlug}
			onActiveChange={handleActiveModuleCallback}
			onModuleComplete={handleSetModuleComplete}
			onModuleForbidden={handleModuleForbidden}
			levelIndentSize={28}
			activeModule={activeModule}
		/>
	);
}