import {CourseTable} from "@plumeuk/shapeshift-common/courses";
import {useNavigate} from "react-router-dom";
import {CenteredContainer} from "../../components/pageBase/centeredContainer";
import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";
import {SideMenuProfileGamification} from "@plumeuk/shapeshift-common/pageBase";

const useStyles = makeStyles()((theme) => ({
	pageBase: {
		[theme.breakpoints.up("lg")]:{
			"& [class*='menuIcon']": {
				display: "none"
			}
		}
	}
}));


export const MyLearningPage: React.FC = () => {
	const navigate = useNavigate();
	const {classes} = useStyles();

	return (
		<PageBase className={classes.pageBase} sideBarContent={<SideMenuProfileGamification />}>
			<CenteredContainer>
				<CourseTable
					sx={{paddingTop: "40px"}}
					onGoToCourseAction={courseId => {navigate("/course/" + courseId)}}
					onGoToCertificateAction={courseId => {navigate(`/course/${courseId}/complete`)}}
				/>
			</CenteredContainer>
		</PageBase>
	);
}