import ReactDOM from "react-dom/client";
import "./fonts/Roobert/RoobertFont.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {defaultTheme} from "./templates/defaultTheme";
import {ThemeProvider} from "@mui/material";
import {AuthProvider, NotificationProvider} from "@plumeuk/shapeshift-identity";
import {CacheProvider} from "@emotion/react";
import createCache from "@emotion/cache";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const getURl = (): string => {
	const url = process.env.REACT_APP_API_HOST;
	if(!url){
		throw new Error("Please set REACT_APP_API_HOST in .env");
	}
	return url;
}

export const muiCache = createCache({
	key: "mui",
	prepend: true
});

root.render(
	<NotificationProvider>
		<AuthProvider contextConfig={{baseApiUrl: getURl()}}>
			<CacheProvider value={muiCache}>
				<ThemeProvider theme={defaultTheme}>
					<App />
				</ThemeProvider>
			</CacheProvider>
		</AuthProvider>
	</NotificationProvider>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
