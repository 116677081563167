export const sideMenuWidth = "400px";
export const toolbarHeight = "80px";

export const plumePalette = {
	darkGrey: "#2A2A2F",
	purple: "#7856FF",
	background: "#F2F1F4",
	error: "#FE4A49",
	success: "#228B22",
	lightGrey: "#d1d1d150",
	Grey: "#d1d1d1"
}