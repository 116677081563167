import {FC} from "react";
import {PageBase} from "../../components/pageBase/pageBase";
import {CourseLibraryBanner} from "./CourseLibraryBanner";
import {useNavigate} from "react-router-dom";
import {Library} from "@plumeuk/shapeshift-common/library";

export const CourseLibraryPage: FC = () => {
	const navigate = useNavigate();

	return (
		<PageBase contentWidth="1300px" disableSideBar>
			<CourseLibraryBanner/>
			<br />
			<Library
				sx={{paddingBottom: "70px"}}
				onGoToItemAction={slug => navigate(`/library/${slug}`)}
			/>
		</PageBase>
	);
}