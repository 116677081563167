import {FC, useContext, useState, useEffect} from "react";
import {Box, BoxProps, Button} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {AuthContext, INotificationSeverity, NotificationContext, useApi} from "@plumeuk/shapeshift-identity";
import {IEditUserRequest, IEditUserResponse, IUser, TEditUserRequest} from "../../types/IUser"
import {ValidationResult, useValidate} from "@plumeuk/use-validate";
import {editUserRequestValidationDoc} from "../../validationDocs/user/editUserDetailsRequestValidationDoc"
import {OutlineButton} from "@plumeuk/shapeshift-common/buttons";
import {InputText} from "@plumeuk/shapeshift-common/inputText";
import {IUser as SSUser} from "@plumeuk/shapeshift-types";
import {useFileBaseUrl} from "@plumeuk/shapeshift-common/hooks";

type IPropsCustom = {
	onClose?: () => void,
	className?: string
}

export type IProps = BoxProps & IPropsCustom;

const useStyles = makeStyles()((theme) => ({
	editAccount:{
	},
	title: {
		marginBottom: "40px",
		display: "block"
	},
	input: {
		marginTop: "20px"
	},
	buttonContainer: {
		gap: "10px",
		marginTop: "40px",
		display: "flex",
		justifyContent: "space-between",
		"& button": {
			[theme.breakpoints.down("sm")]: {
				width: "100%",
				marginBottom: "20px"
			}
		}
	}
}));

const convertContextUser = (user: SSUser): IEditUserRequest => {
	const userLocalTyped = user as unknown as IUser;

	return ({
		firstname: userLocalTyped.firstname,
		lastname: userLocalTyped.lastname,
		email: userLocalTyped.email,
		username: userLocalTyped.username
	});
}


export const EditUserDetailsForm: FC<IProps> = ({className, onClose, ...props}) => {
	const {classes, cx} = useStyles();
	const {notify} = useContext(NotificationContext);
	const {user, setUser} = useContext(AuthContext);
	const [formData, setFormData] = useState<IEditUserRequest>(user ? convertContextUser(user) : TEditUserRequest);
	const [updateUserResponse, updateUser] = useApi<IEditUserResponse>();
	const [validationResult, runValidate, hasErrors] = useValidate<IEditUserRequest>(editUserRequestValidationDoc, formData);
	const [postRequestValidation, setPostRequestValidation] = useState<ValidationResult<IEditUserRequest>>({});

	useEffect(() => {
		if(!user) return;
		setFormData(convertContextUser(user));
	}, [user])

	const handleSubmit = async (): Promise<void> => {
		setPostRequestValidation({});

		if(!runValidate())
			return;

		const payloadFormData = new FormData();
		Object.keys(formData).forEach((e) => {
			const key = e as keyof IEditUserRequest;
			const data = formData[key];

			if(data)
				payloadFormData.append(key, data as any);
		})

		updateUser({
			method: "PUT",
			url: "/api/user",
			data: payloadFormData,
			config: {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			}
		})
	}


	useEffect(() => {
		if(updateUserResponse.data && !updateUserResponse.isError && !updateUserResponse.isLoading){
			notify("", "User details updated!", INotificationSeverity.success, 5000);
			setUser(prev => {
				if(!prev || !updateUserResponse.data)
					return;

				return {
					...prev,
					...updateUserResponse.data
				}
			})
			onClose?.();
		}
		else if (updateUserResponse.isError){
			const error: any = {};
			const message = updateUserResponse?.errorData?.error?.message;
			if(message?.toLowerCase()?.includes("email")){
				error.email = message;
			}
			if(updateUserResponse?.errorData?.error?.message?.toLowerCase()?.includes("username")){
				error.username = message;
			}
			setPostRequestValidation(error)
		}
	}, [updateUserResponse]);

	const input = (label: string, prop: keyof IEditUserRequest): JSX.Element => (
		<InputText
			className={classes.input}
			label={label}
			type="text"
			autoComplete="off"
			value={formData[prop]}
			error={validationResult[prop] ?? postRequestValidation[prop]}
			onChange={e => setFormData(prev => ({...prev, [prop]: e.target.value}))}
			onValidate={() => runValidate(prop)}
		/>
	)

	return (
		<Box className={cx(classes.editAccount, className)} {...props}>
			<form autoComplete="off">
				{input("First Name", "firstname")}
				{input("Last Name", "lastname")}
				{input("Email", "email")}
				{input("Username", "username")}
			</form>
			<Box className={classes.buttonContainer}>
				<Button disabled={hasErrors} onClick={handleSubmit}>Save changes</Button>
				{onClose && <OutlineButton onClick={onClose}>Cancel changes</OutlineButton>}
			</Box>
		</Box>
	);
}

export default EditUserDetailsForm;