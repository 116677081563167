import {FC, useEffect, useState} from "react";

import {makeStyles} from "tss-react/mui";
import {Box, LinearProgress} from "@mui/material";
import {fetchIMSManifest, getModuleURLFromIMSManifestContent} from "@plumeuk/shapeshift-common/common";
import "scorm-again/dist/scorm2004";
import {IScorm} from "@plumeuk/shapeshift-types";
import {useDebounce, useSetScormSessionData} from "@plumeuk/shapeshift-common/hooks";

const useStyles = makeStyles()(() => ({
	container: {
		width: "100%",
		height: "500px",
		border: "none",
		overflow: "hidden"
	},
	iframe: {
		width: "100%",
		height: "100%",
		border: "none"
	},
	loading: {
		width: "100%"
	}
}));

interface IProps {
	manifestUrl: string;
	className?: string;
	onInitialize?: () => void;
	onFinish?: () => void;
	onCommit?: () => void;
	courseSlug?: string,
	scorm?: IScorm,
	onSetValue?: (prop: string, value: string) => void;
	onComplete?: () => void
}

export const ScormPlayer: FC<IProps> = ({manifestUrl, className, onCommit, onFinish, onInitialize, onSetValue, courseSlug, scorm, onComplete}) => {
	const {classes, cx} = useStyles();
	const [loading, setLoading] = useState<boolean>(true);
	const [moduleUrl, setModuleUrl] = useState<string | undefined>(undefined);
	const [sessionData, setSessionData] = useState<Object>();
	const sessionDataDebounced = useDebounce(sessionData, 3000);
	const {saveSessionData} = useSetScormSessionData();

	useEffect(() => {
		if(!scorm)
			return;

		(window as any)["API_1484_11"] = new (window as any)["Scorm2004API"]();
		const API = (window as any)["API_1484_11"];
		API.loadFromJSON(
			scorm.sessionData
		);

		API.on("Initialize", onInitialize?.());

		API.on("Commit", onCommit?.());

		API.on("SetValue", function(prop: string, value: string) {
			onSetValue?.(prop, value);
			if(prop === "cmi.completion_status" && value === "completed") {
				onComplete?.();
			}
			setSessionData(prev => ({...prev, [prop.replace("cmi.", "")]: value}));
		});

		API.on("Terminate", onFinish?.());
	}, [scorm]);

	useEffect(() => {
		if(courseSlug && scorm?.slug && sessionDataDebounced)
			saveSessionData(courseSlug, scorm.slug, sessionDataDebounced)
	}, [sessionDataDebounced])

	useEffect(() => {
		fetchManifest();
	}, []);

	const fetchManifest = async (): Promise<void> => {
		const manifest = await fetchIMSManifest(manifestUrl);
		const moduleUrl = getModuleURLFromIMSManifestContent(manifestUrl, manifest);

		setModuleUrl(moduleUrl);
	};

	return (
		<Box className={cx(classes.container, className)}>
			{(loading || !scorm) && <div className={classes.loading}>
				<LinearProgress color="secondary" />
			</div> }
			{scorm && <iframe
				src={moduleUrl}
				allowFullScreen
				className={classes.iframe}
				onLoad={() => setLoading(false)}
			/>}
		</Box>
	)
};

export default ScormPlayer;