import {FC, useCallback, useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Button, Typography} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {makeStyles} from "tss-react/mui";
import {ModuleCompleteButton, ModuleNotAvailable, ModuleTabs, ModuleTab, ModuleUnCompleteButton} from "@plumeuk/shapeshift-common/module";
import {PageTitle} from "@plumeuk/shapeshift-common/pageBase";
import {NotesSection} from "@plumeuk/shapeshift-common/notes";
import {Files} from "@plumeuk/shapeshift-common/files";
import {CommentsSection} from "@plumeuk/shapeshift-common/comments";
import {Links} from "@plumeuk/shapeshift-common/links";
import {INotificationSeverity, APIState, NotificationContext} from "@plumeuk/shapeshift-identity";
import {WYSIWYG} from "../../../components/WYSIWYG/WYSIWYG";
import {CenteredContainer} from "../../../components/pageBase/centeredContainer";
import {CurriculumContext} from "../../../contexts/curriculumContext";
import {LessonVideoPlayer} from "./LessonVideoPlayer";
import {VideoPlayerRef} from "@plumeuk/shapeshift-common/videoPlayer/videoPlayer";
import {getModule} from "@plumeuk/shapeshift-common/common";
import {ICourseCurriculumModule} from "@plumeuk/shapeshift-types";
import {LessonDataProvider} from "@plumeuk/shapeshift-common/providers";
import {IModuleEnrollment} from "@plumeuk/shapeshift-common/types";

const useStyles = makeStyles()((theme) => ({
	lessonPage: {
		paddingTop: "50px",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "20px"
		}
	},
	title:{
		marginBottom: "40px",
		[theme.breakpoints.down("sm")]: {
			marginBottom: "30px"
		}
	},
	centeredContainer: {
		paddingBottom: "100px",
		maxWidth: "900px"
	},
	lessonCompleteBtn: {
		float: "right"
	},
	content: {
		marginBottom: "40px",
		"& p": {
			lineHeight: 1.5,
			fontSize: "18px"
		}
	},
	contentImage: {
		maxWidth: "100%"
	},
	fileTitle: {
		fontWeight: 700,
		margin: "20px 0"
	},
	files: {
		marginBottom: "40px"
	},
	nextBtn: {
		position: "absolute",
		[theme.breakpoints.down("sm")]: {
			margin: 0,
			borderRadius: "0",
			left: 0,
			top: "240px",
			padding: "14px 0",
			right: "0px",
			"& svg": {
				fontSize: "20px"
			}
		},
		[theme.breakpoints.up("sm")]: {
			zIndex: 10,
			background: "#fff",
			color: theme.palette.secondary.main,
			right: "-160px",
			fontSize: "22px",
			opacity: 0.7,
			top: "200px",
			borderRadius: 100,
			padding: "22px 150px 22px 22px",
			transition: "all 0.2s ease-in-out",
			"&:hover": {
				opacity: 1,
				right: "-140px",
				color: theme.palette.secondary.main,
				background: "#fff"
			},
			"& span": {
				display: "none"
			},
			"& svg": {
				fontSize: "48px"
			}
		}
	},
	tabs: {
		marginBottom: "50px"
	},
	links: {
		marginBottom: "40px"
	},
	tab: {
		color: "#A8A8A8",
		fontSize: "18px"
	},
	activeTab: {
		color: "#2A2A2F !important"
	},
	goToNextLesson: {
		marginTop: "25px"
	},
	notAvailableContainer: {
		width: "100%",
		textAlign: "center"
	}
}));


export const LessonPage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const {moduleSlug: lessonSlug} = useParams();
	const {curriculumState: curriculum, curriculumDispatch} = useContext(CurriculumContext);
	const {notify} = useContext(NotificationContext);
	const navigate = useNavigate();
	const [tabSelected, setTab] = useState<ModuleTab>("overview");
	const [firstAvailableModule, setFirstAvailableModule] = useState<ICourseCurriculumModule | false>();

	useEffect(() => {
		if(courseSlug && lessonSlug && curriculum)
			setFirstAvailableModule(getModule(curriculum) ?? false)
	}, [courseSlug, lessonSlug, curriculum])

	const handleCompletion = useCallback((e: APIState<IModuleEnrollment>): void => {
		if(e.isError) {
			notify("Please try again", "Something went wrong", INotificationSeverity.error, 5000);
		}
		else if(!e.isLoading && !e.isError && e.statusCode === 200){
			const status = !!e?.data?.complete;
			curriculumDispatch({
				type: "setModuleComplete", module: {type: "lesson", slug: lessonSlug as string}, status})

			if(e?.data?.complete){
				notify(null, "Lesson completed", INotificationSeverity.success, 5000);
				navigate("/course/" + courseSlug)
			}
			else {
				notify("Set to incomplete", "Lesson Updated", INotificationSeverity.success, 5000);
			}
		}
	}, [curriculumDispatch, lessonSlug])

	const videoRef = useRef<VideoPlayerRef>(null);

	return (
		<Box className={classes.lessonPage}>
			<LessonDataProvider courseSlug={courseSlug} lessonSlug={lessonSlug} curriculum={curriculum}>
				{({lesson, apiResult}) => <>
					{apiResult.statusCode === 200 && <>
						{lesson?.videoUrl && <>
							<LessonVideoPlayer ref={videoRef} lesson={lesson} />
							{courseSlug && <ModuleCompleteButton
								courseSlug={courseSlug}
								moduleSlug={lesson.slug}
								type="lesson"
								className={classes.nextBtn}
								onApiUpdate={handleCompletion}
							>
								{() => <><span>Next Lesson</span> <NavigateNextIcon/></>}
							</ModuleCompleteButton>}

						</>}
						<CenteredContainer className={classes.centeredContainer}>
							<ModuleTabs moduleTabs={[{label: "Overview", value: "overview"}, {label: "Comments", value: "comments"}, {label: "Notes", value: "notes"}]} tabSelected={tabSelected} onChange={tab => setTab(tab)} className={classes.tabs} />
							{tabSelected === "overview" && <>
								<PageTitle className={classes.title} title={lesson?.title} subtitle={lesson?.subtitle} />
								<WYSIWYG className={classes.content}>
									{lesson?.content ?? ""}
								</WYSIWYG>

								{lesson?.files && <>
									<Typography className={classes.fileTitle} variant="h5">Files</Typography>
									<Files className={classes.files} files={lesson.files}/>
								</>}

								{lesson?.links && lesson.links.length > 0 && <>
									<Typography className={classes.fileTitle} variant="h5">Links</Typography>
									<Links className={classes.links} links={lesson.links}/>
								</>}

								{courseSlug && lesson?.slug && !lesson?.complete &&
									<ModuleCompleteButton
										courseSlug={courseSlug}
										moduleSlug={lesson.slug}
										type="lesson"
										className={classes.lessonCompleteBtn}
										onApiUpdate={handleCompletion}
									/>
								}
								{courseSlug && lesson?.slug && lesson?.complete &&
									<ModuleUnCompleteButton
										courseSlug={courseSlug}
										moduleSlug={lesson.slug}
										type="lesson"
										className={classes.lessonCompleteBtn}
										onApiUpdate={handleCompletion}
									/>
								}
							</>}
							{tabSelected === "comments" && <CommentsSection courseSlug={courseSlug} moduleType={"lesson"} moduleSlug={lessonSlug}/>}
							{tabSelected === "notes" && <NotesSection courseSlug={courseSlug} moduleType="lesson" moduleSlug={lessonSlug} videoRef={videoRef} />}
						</CenteredContainer>
					</>}
					{apiResult.statusCode === 403 && <Box className={classes.notAvailableContainer}>
						<ModuleNotAvailable>
							{apiResult.errorData?.error.message}. We&apos;ll email you when it&apos;s available
						</ModuleNotAvailable>
						{firstAvailableModule && <Button
							className={classes.goToNextLesson}
							onClick={() => navigate(`/course/${courseSlug}/${firstAvailableModule?.type}/${firstAvailableModule?.slug}`)}
						>
							Go to next available lesson
						</Button>}
						{firstAvailableModule === false && <Button
							className={classes.goToNextLesson}
							onClick={() => navigate("/my-learning")}
						>
							Go back to My Learning
						</Button>}
					</Box>}
				</>}
			</LessonDataProvider>
		</Box>
	);
}