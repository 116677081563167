import {FC, useCallback, useContext, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {CenteredContainer} from "../../../../components/pageBase/centeredContainer";
import {SurveyQuestionContainer} from "./SurveyQuestion/SurveyQuestionContainer";
import {SurveyQuestionsDataProvider} from "@plumeuk/shapeshift-common/providers";
import {useSubmitSurveyResponse} from "@plumeuk/shapeshift-common/hooks";
import {LinearProgress} from "@mui/material";
import {CurriculumContext} from "../../../../contexts/curriculumContext";
import {ISurvey, ISurveyAnswer} from "@plumeuk/shapeshift-types";
import {PageTitle} from "@plumeuk/shapeshift-common/pageBase";
import {WYSIWYG} from "../../../../components/WYSIWYG/WYSIWYG";
import {plumePalette} from "../../../../constants";

const useStyles = makeStyles()((theme) => ({
	centeredContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		marginTop: "40px",
		textAlign: "left",
		"& [class*='questionContainer']":{
			"& h3": {
				fontStyle: "normal",
				fontWeight: 800,
				fontSize: "26px",
				lineHeight: "30px",
				[theme.breakpoints.down("sm")]: {
					marginTop: "7px"
				}
			}
		},
		"& [class*='questionHint'] [class*='bodyContainer'] [class*='body']": {
			color: theme.palette.common.black,
			opacity: 0.5
		},
		"& [class*='optionContainer'] [class*='questionOption']": {
			borderRadius: "78px",
			borderWidth: "1px",
			"& [class*='optionId']": {
				fontWeight: 800,
				borderWidth: "1px"
			}
		},
		"& [class*='questionContainer']:not(:first-child)": {
			borderTop: `1px solid ${plumePalette.Grey}`,
			marginTop: "46px"
		},
		"& [class*='questionCounter']": {
			color: "#7A9097",
			fontSize: "16px",
			fontWeight: "700",
			lineHeight: "35px",
			textTransform: "uppercase",
			[theme.breakpoints.down("sm")]: {
				fontSize:  "14px"
			}
		},
		"& [class*='answerCounter']": {
			fontSize: "15px",
			fontWeight: "600",
			lineHeight: "18px",
			padding: "11px 20px",
			textAlign:  "center",
			background: plumePalette.lightGrey,
			color: plumePalette.Grey,
			[theme.breakpoints.down("sm")]: {
				fontSize:  "14px",
				padding: "10px 16px"
			}
		}
	},
	title: {
		marginBottom: "18px",
		"& h2": {
			fontSize: "58px"
		},
		"& h5": {
			fontSize: "24px",
			lineHeight: "46px",
			marginTop: "0px !important"
		},
		[theme.breakpoints.down("sm")]: {
			marginBottom: "10px",
			"& h2": {
				fontSize: "42px"
			},
			"& h5": {
				fontSize: "20px",
				marginTop: "-10px !important"
			}
		}
	},
	content: {
		marginBottom: "10px",
		paddingBottom: "20px",
		borderBottom: `${plumePalette.lightGrey} solid 1px`,
		"& *:first-child": {
			marginTop: "0px",
			paddingTop: "0px"
		},
		"& p": {
			color: plumePalette.Grey,
			fontSize: "18px",
			fontWeight: 400,
			lineHeight: "160%",
			[theme.breakpoints.down("sm")]: {
				fontSize: "17px"
			}
		}
	}
}));

interface IProps {
	survey?: ISurvey
}

export const SurveyQuestions: FC<IProps> = ({survey}) => {
	const {classes} = useStyles();
	const {courseSlug, moduleSlug: surveySlug} = useParams();
	const {submitResponse, response} = useSubmitSurveyResponse();
	const navigate = useNavigate();
	const {curriculumDispatch} = useContext(CurriculumContext);

	useEffect(() => {
		if(!response || !surveySlug)
			return;

		curriculumDispatch({
			type: "setModuleComplete", module: {type: "survey", slug: surveySlug}, status: true})

		navigate("../complete")
	}, [response, surveySlug]);

	if(!courseSlug || !surveySlug) return <LinearProgress />

	return (
		<CenteredContainer className={classes.centeredContainer}>
			<PageTitle className={classes.title} title={survey?.title} subtitle={survey?.subtitle} />
			<WYSIWYG className={classes.content}>
				{survey?.content ?? ""}
			</WYSIWYG>
			<SurveyQuestionsDataProvider courseSlug={courseSlug} surveySlug={surveySlug}>
				{({surveyQuestions}) =>
					<SurveyQuestionContainer
						questions={surveyQuestions}
						response={survey?.response}
						onSubmit={(answers) => submitResponse(courseSlug, surveySlug, answers)}
					/>
				}
			</SurveyQuestionsDataProvider>
		</CenteredContainer>
	);
}